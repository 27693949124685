import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "ant-row" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_empresa_cadastro_compartilhado = _resolveComponent("selecionar-empresa-cadastro-compartilhado")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      centered: "",
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      "wrap-class-name": "ss-modal-cadastros"
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Cadastro de Unidade "),
        _createVNode(_component_a_switch, {
          checked: _ctx.state.unidade.ativo,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.unidade.ativo) = $event)),
          style: {"float":"right"},
          "checked-children": "Ativa",
          "un-checked-children": "Inativa",
          disabled: !_ctx.state.editavel
        }, null, 8, ["checked", "disabled"])
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        (!_ctx.state.possuiMovimentacao)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                      || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "ant-btn ant-btn-lg ant-btn-secondary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(true)))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvar(false)))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      label: "Empresas",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_selecionar_empresa_cadastro_compartilhado, {
                          identificadorRecurso: _ctx.telaBase.identificadorRecurso,
                          codigosSelecionados: _ctx.telaBase.empresasSelecionadas,
                          "onUpdate:codigosSelecionados": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event))
                        }, null, 8, ["identificadorRecurso", "codigosSelecionados"])
                      ]),
                      _: 1
                    })
                  ])
                ], 512), [
                  [_vShow, _ctx.telaBase.apresentarEmpresas]
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_a_form_item, {
                      label: "Descrição",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "250",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.unidade.descricao) = $event)),
                          disabled: !_ctx.state.editavel
                        }, null, 8, _hoisted_6), [
                          [_vModelText, _ctx.state.unidade.descricao]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_a_form_item, {
                      label: "Sigla",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "5",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.unidade.sigla) = $event)),
                          disabled: !_ctx.state.editavel
                        }, null, 8, _hoisted_9), [
                          [_vModelText, _ctx.state.unidade.sigla]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_a_form_item, {
                      label: "Casas Decimais",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.state.unidade.casasDecimais,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.unidade.casasDecimais) = $event)),
                          disabled: !_ctx.state.editavel
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select_option, { value: "0" }, {
                              default: _withCtx(() => [
                                _createTextVNode("0")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "1" }, {
                              default: _withCtx(() => [
                                _createTextVNode("1")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "2" }, {
                              default: _withCtx(() => [
                                _createTextVNode("2")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "3" }, {
                              default: _withCtx(() => [
                                _createTextVNode("3")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "4" }, {
                              default: _withCtx(() => [
                                _createTextVNode("4")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "5" }, {
                              default: _withCtx(() => [
                                _createTextVNode("5")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "6" }, {
                              default: _withCtx(() => [
                                _createTextVNode("6")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "7" }, {
                              default: _withCtx(() => [
                                _createTextVNode("7")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "8" }, {
                              default: _withCtx(() => [
                                _createTextVNode("8")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_a_form_item, {
                      label: "Fracionar quantidade",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio_group, {
                          value: _ctx.state.unidade.fracionar,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.unidade.fracionar) = $event)),
                          "button-style": "solid",
                          disabled: !_ctx.state.editavel
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio_button, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode("Não")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio_button, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode("Sim")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value", "disabled"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                (_ctx.state.possuiMovimentacao)
                  ? (_openBlock(), _createBlock(_component_a_alert, {
                      key: 0,
                      style: {"margin-top":"30px"},
                      message: "As alterações nessa unidade estão bloqueadas no momento. Pois a mesma está vinculada a produtos e movimentações.",
                      description: "Você pode desvincular nos produtos ou criar uma nova.",
                      type: "warning",
                      "show-icon": ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
          fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso,
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "apresentarBarraProgresso", "fraseBarraProgresso", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}